import React,{useState} from "react";
import Navbar from "../components/navbar";
import { Link } from "react-router-dom";

const Events = () => {
    const [selectedCategory, setSelectedCategory] = useState("Completed Events");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
      };
    const data = [
        {
          id: 1,
          title: "Old Painting Restore Workshop",
          place: 'Government Museum, Madurai',
          status:'Completed Events',
          category: 'Workshop',
          description: `Industrialization and globalization have reached all corners of the
          globe, our wars are ever more destructive, and the planet is
          cracking under our pressure. We live in the consumerist era, and
          this might be our downfall. Our civilization’s collapse might
          prove more cataclysmic than that of any ancient culture.`,          
          image: "event2.jpg"
        },
        {
          id: 2,
          title: "Clay sculpting workshop ",
          status:'Completed Events',
          place: 'American College of Arts and Science, Madurai',
          category: 'Workshop',
          description: `Industrialization and globalization have reached all corners of the
          globe, our wars are ever more destructive, and the planet is
          cracking under our pressure. We live in the consumerist era, and
          this might be our downfall. Our civilization’s collapse might
          prove more cataclysmic than that of any ancient culture.`,          
          image: "event3.JPG"
        },
        {
          id: 3,
          title: " “Parai” Tamil traditional musical instrument making workshop",
          place: 'Kochi Muziris Biennale, Kochi',
          category: 'Workshop',
          status:'Completed Events',
          description: `Industrialization and globalization have reached all corners of the
          globe, our wars are ever more destructive, and the planet is
          cracking under our pressure. We live in the consumerist era, and
          this might be our downfall. Our civilization’s collapse might
          prove more cataclysmic than that of any ancient culture.`,
          image: "event1.jpg"
        },
        {
          id: 4,
          title: "Chennai Photo Biennale: Screening for Documentary on Keezhadi Excavation labours ",
          place: ' Chennai',
          status:'Completed Events',
          category: 'Screening',
          description: `Industrialization and globalization have reached all corners of the
          globe, our wars are ever more destructive, and the planet is
          cracking under our pressure. We live in the consumerist era, and
          this might be our downfall. Our civilization’s collapse might
          prove more cataclysmic than that of any ancient culture.`,          
          image: "keezhadi.png"
        },
      ];

  const categories = ["Completed Events","Upcoming Events"]
  return(
    <div>
    <Navbar />
    <div className="flex flex-col gap-4 mt-8" >
    <div className="flex items-center justify-between px-5 md:px-20"><p className="md:text-4xl text-xl sm:text-lg font-semibold text-sky curvy-underline">Events</p>
    <div className="flex flex-col">                     
    <button className="dropdown-toggle p-1 md:w-56 w-40 bg-highlight text-white" onClick={() => setDropdownOpen(!dropdownOpen)}>
                {selectedCategory}
              </button>      
              {dropdownOpen && (
                <div className="dropdown-menu absolute flex flex-col items-start z-10 w-56 mt-8 bg-white border border-gray-300 mt-1 py-1 px-2">
                  {categories.map((category, index) => (
                    <div className="">
                    <button className="hover:text-pink" key={index} onClick={() => { handleCategoryChange(category); setDropdownOpen(false) }}>
                      {category}
                    </button>
                    </div>
                  ))}
                </div>
              )}
              </div> 
    </div>
    <div className='flex flex-wrap max-w-full px-1 md:gap-20 md:px-20 py-10'>
    {data.filter((event)=> event.status === selectedCategory).map((event)=>(
    <Link to={`/events/${event.id}`}>
    <div key={event.id} className='flex flex-col  border border-gray-200 h-[29rem] rounded-md md:w-auto  '>
<div className="relative">
<img className="w-full h-64" src={event.image} alt="" />
<div className="bg-pink absolute bottom-0 text-white rounded-r-full w-28 py-1 flex justify-center">
    <span className=" uppercase text-sm font-bold">{event.category}</span>
        </div>
</div>
    <div className='flex flex-col gap-3 py-2'>
      <div className='flex justify-between items-start'>
    <div className='flex items-start w-[21rem] flex-col gap-2'>
        <div className="flex flex-col px-4 w-full">
        <span className='text-lg font-semibold line-clamp-2'>{event.title}</span>
        <span className="text-pink text-sm font-semibold">{event.place}</span>
        <span className='text-sm line-clamp-5'>{event.description}</span>
        </div>
        </div>
    </div>
 </div>
 </div>
 </Link>
    ))}
</div>
    </div>
</div>
  );
};

export default Events;
