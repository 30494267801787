import React,{useState} from "react";
import Navbar from "../components/navbar";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const ArticlesPage = ()=>{
    const [selectedCategory, setSelectedCategory] = useState("Articles");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
      };
    const data = [
        {
        id:1,
        newspage:'The Hindu',
        img:'chennaiphotobiennale.jpg',
        date:'Dec 10, 2021',
        newsheading:'Third edition of Chennai Photo Biennale kicksoff',
        category:"Articles",
        pagelink:'https://www.thehindu.com/news/national/tamil-nadu/third-edition-of-chennai-photo-biennale-kicks-off/article37920298.ece',
        newsdetails:"It features works of artists from across 11 countriesFrom physical exhibition to virtual and in-person guided tours, with over 1,500 imag- es of 51 artists, the third edi- tion of Chennai Photo Bien- nale was launched on Thursday at the Roja Muth- iah Research Library. In a span of 60 days begin- ning December 9, they will display the works of artists and contributors from 11 countries which will include talks by artists, digital screening, physical and vir- tual exhibition, book release and virtual and in-person guided tours. Titled 'Maps of Disquiet', the event will be curated by PS EDISC JETER SFDISO Team work: Artists seen with Tourism Minister Mathiventhan at the inauguration of the third edition of Chennai Photo Biennale on Thursday. B. VELANKANNI RAJ Arko Datto, Bhooma Padma- nabhan, Boaz Levin and Ker- stin Meincke. Varun Gupta, photogra- pher and director of Chennai Photo Biennale, said after the pandemic hit, they had to make modifications to adapt to displaying the art in smaller private spaces, tak ing people's health into con- sideration. Chennai is a cultural cap- ital and the biennale is part of it. One of the key aspects of the event is the exhibits about the Keeladi by V. Sa- ranraj. He has displayed the portraits of labourers who are working in the excava- tion pits of Keeladi. The pur- pose is to humanise the exca- vation process Tourism Minister Mathi- venthan said Tamil Nadu go- vernment is committed to having an association with this event and will support it. We will take it forward and project it in a larger way. There is no one who may not fall for art. When it is pre- sented in a beautiful way in any form, it will be well ap preciated, he said B. Chandramohan, princi- pal secretary, Tourism, Cul- ture and Religious Endow- ments Department, said the State had emerged as the number one destination in attracting domestic tourists. and in international tourists,"   },
        {
            id:2,
            newspage:'The New Indian Express',
            img:'exhibit.jpg',
            date:'Nov 28, 2021',
            category:"Articles",
            newsheading:'A window that opens to the world',
            pagelink:'https://www.newindianexpress.com/states/tamil-nadu/2021/Nov/28/a-window-that-opens-to-the-world-2389037.html',
            newsdetails:"Loud upbeat music echoed through the streets of Karadipatti village and multicoloured lights were strung up on houses in Ambedkar Colony, Madurai district. The hamlet, with 100 Scheduled Caste families, was in a celebratory mode, and for once, it was not for the usual tem- ple festival. A huge painting of Babasaheb Ambedkar greets visitors at the entrance of their newly-built li- brary, illustrated by a 31-year-old artist V Saran Raj and his friends. Murals of Vaikom Muhammad Basheer, Little Prince, and D Jay- akanthan usher children inside the single-room building. Readers can choose from 1,900 books, the only ones the colony has owned. The colony was ecstatic they have a library and the paintings. Something the settlement never had until April 2021, the 130th birth anniversary of Ambedkar. In January 2021, Saran posted on social media platforms: a call to donate books. The shelves of what would soon become Ambedkar Li- brary rapidly filled with 1,500 books from strangers and friends alike. Neelam Cultural Center and Vaasagar Saalai in Chennai also sent free magazines. By April, the library was open to visitors. This library hopes to help chil- dren like S Dharshini studying in Class X who get to see books only in classrooms-initiate a conversation with the world. We don't have libraries nearby and this was the only source of read- ing apart from textbooks"   },
            {
                id:3,
                newspage:'The New Indian Express',
                img:'seaspice.png',
                date:'Mar 18, 2020',
                category:"Articles",
                newsheading:'Stories of Sea and Spices',
                pagelink:'https://www.newindianexpress.com/cities/kochi/2020/Mar/18/stories-of-sea-and-spices-2117917.html',
                newsdetails:"Over the last few months, Madurai- based artist Saran Raj V has been strolling through the streets of Fort Kochi, trying to get a pulse of the historic trade city There, he found a social milieu far more complex than anything he had expected. It was as fascinating as his hometown. Unsurprisingly the spice market captivated him, so did the headload workers carrying sacks to the godowns, the women cleaning the streets before day- break and the fishermen hoping for a good catch with the Chinese fish- ing nets. But most serendipitously, Saran smelled a whiff of something familiar amid the fluttering sheets of white at Veli Dhobi Khana. În finding that many washer men and women spoke his language, he spent hours talking to them and record- ing what they have to say Their sto- ries form the focal point of Saran's ongoing open studio at URU Art Harbour, a culmination of his year- long residency Mirroring the clotheslines at Veli, Saran propped a long coir rope on wooden stilts in the gallery. Hanging from the rope are pieces of doth, each inscribed with an apho- rism culled out from the audio re- cordings of washer men. Next to the rope is a large piece of plywood splayed out on the floor, charred in places with imprints of a coal iron"    },
                {
                    id:4,
                    newspage:'Kungumam',
                    img:'keezhadi.png',
                    date:'Feb 2, 2022',
                    category:"Articles",
                    newsheading:'The first documentary about the Keezhadi Excavation laborers',
                    pagelink:'http://kungumam.co.in/Articalinnerdetail.aspx?id=19085&id1=4&issue=20220220',
                    newsdetails:"Generally, underemployment issues, its history, the tools used there, its stories, and its political news all come to light. But for the past seven years, nobody knows about the works of those workers. They're trying to show their work to the world. That's why I made a documentary... she paused thoughtfully.Recently, in Chennai, as part of a two-month-long art exhibition, his documentary, 'Light - Shadow in the Depths,' featuring the narratives of underemployed workers, was screened, capturing the essence of many people's struggles. This documentary, aiming to bring attention to underemployment issues, has received positive feedback from various quarters. Alongside displaying photographs related to underemployment, there's also an eagerness for sponsorship. I feel very happy. I hope to get such a good response. How will I collect materials for ten documentaries? What's the production process? Who will be involved? How do we prepare reports? How will we document tasks that are unknown to the public? We'll just go ahead and make a documentary about it. But I can't remember it."
                 },{
                        id:5,
                        newspage:'Tamil Hindu',
                        img:'tamilhindu.png',
                        date:'Dec 10, 2021',
                        category:"Articles",
                        newsheading:'Limestone workers Aspirations and Paintings',
                        pagelink:'https://www.hindutamil.in/news/supplements/ilamai-puthumai/523613-painting.html',
                        newsdetails:"The art organization 'Perch,' recently showcased 'Srikai Vri' in Chennai. This exhibition took place at Max Mueller Bhavan for two days. The exhibition featured plays, paintings, films, and short films by seven artists. Under the initiative of the 'Perch' organization, a total sum of Rs. 30,000 was provided to the seven selected artists to create their works. Among the participating artists were Rama Revathi, V. Saranraj, J. Daksini, K. Padmapriya, Muthuvel, Navaneeth, and Pagu. Artist Saranraj, originally from Madurai, showcased his works portraying the lives of workers in the local limestone industry. He highlighted their struggles through various art forms such as paintings, sculptures, and audio recordings. Saranraj expressed concern over the dwindling workforce in the limestone industry, which once supported more than 300 livelihoods but now only sustains around 50 workers. He emphasized the adverse effects on the local economy if the limestone industry were to collapse. Saranraj recalled a day when he visited the limestone quarry and observed the quietude due to lack of work. He expressed solidarity with both the workers and the community, highlighting the precariousness of their livelihoods should the limestone industry decline further."   },
                        {
                            id:6,
                            newspage:'Vikatan',
                            img:'vikatan.jpg',
                            date:'Feb 2, 2022',
                            category:"Articles",
                            newsheading:'Keezhadi brought to kochi! -  Artists who bring pride to Tamil Nadu! -An Overview',
                            pagelink:'https://cinema.vikatan.com/arts/story-about-tamilnadu-artists-show-at-kochi-biennale-2023',
                            newsdetails:"The city is always crowded with tourists not only from India but also from abroad. Port Kochi Mattancherry region is famous for painting artists. The stretch of Mattancherry Zoo Town, about 3 km long, is lined with tiled high-rise buildings. Most of the scenes of the film 'Maara' starring actor Madhavan have been shot in the region. Look at the picture again. You will be amazed by this beautiful road. The most important art exhibition in the world is held every three years in Venice. Since 2012, it has been held biennially in Kochi, India."
                         },
                         {
                            id:7,
                            newspage:'Kamathenu',
                            img:'chandrusirsaranraj.jpg',
                            date:'Feb 2, 2022',
                            category:"Articles",
                            newsheading:'The first documentary about the Keezhadi Civilization laborers',
                            pagelink:'https://kamadenu.hindutamil.in/Art/keeladi-excavation-labourers-make-my-art-says-saran-raj?fbclid=IwZXh0bgNhZW0CMTEAAR1r553FJqAQytOk2P7M4FjN8u18etL4QjLP44h9R6cdI1kYBe5wfH-CXks_aem_AU1lYePSfw6zlpkbJDpz2yPyAFWFTYH_T-qiGP2qwkcSlMywh6CTwDPUGQA5GNXksIlP78s7EbQyqlt7Bat5XrRu',
                            newsdetails:"We take pride in the fact that the ancient artifacts found in the excavations at Keezhadi tell the story of the Tamils. We are aware of the senior officials involved in this excavation work. But do we know the workers who dig up the identities of the indigenous Tamils? Saran Raj is trying to bring the emotions and sentiments of these people to the world through his art. The Chennai Photo Biennale will be held on December 9 at Roja Muthiah Research Library, Taramani, Chennai. It will showcase the key moments of the Keezhadi excavators captured by Charan Raj's camera."
                         },
                         {
                            id:8,
                            newspage:'The Hindu',
                            img:'ranjithvaanam.jpg',
                            date:'Apr 24, 2024',
                            category:"Articles",
                            newsheading:'Dalit art and aesthetics by Indian artists on display at Chennai’s Lalit Kala Akademi',
                            pagelink:'https://www.thehindu.com/entertainment/art/dalit-art-and-aesthetics-by-indian-artists-on-display-at-chennais-lalit-kala-akademi/article68100843.ece',
                            newsdetails:"What is the month of April synonymous with apart from the scorching summer? Resistance. For the last three years, Pa Ranjith’s Neelam Cultural Centre has honoured the birth month of BR Ambedkar with unbridled celebration. The month-long Vaanam Art Festival is now a cultural event that the city looks forward to every year. Talks, panel discussions, photo and art exhibits, performances and film screenings that champion the cause of Dalit resistance and social justice mark this event, which aims at fostering an environment for healthy dialogue. This year, the walls of Lalit Kala Akademi will showcase the works of 30 artists in an exhibition titled Curve, as we approach the end of the festival. "
                         },{
                            id:9,
                            newspage:'NT Media',
                            img:'chennaiphotobiennale.jpg',
                            pagelink:'https://www.youtube.com/watch?v=ROlAX7dhovY',
                            category:"Videos",
                         },
                         {
                            id:10,
                            newspage:'News18 Tamil Nadu',
                            img:'pt.jpg',
                            pagelink:'https://www.youtube.com/watch?v=4icc-fnFzT0',
                            category:"Videos",
                         },
                         {
                            id:11,
                            newspage:'Chennai Photo Biennale',
                            pagelink:'https://www.youtube.com/watch?v=b5fN8W1s3IM',
                            img:'cpb.png',
                            category:"Videos",
                         },
                         {
                            id:12,
                            newspage:'Strong tea',
                            pagelink:'https://www.youtube.com/watch?v=rCbOyzx2uec',
                            img:'strongtea.jpg',
                            category:"Videos",
                         },
                         {
                            id:13,
                            img:'iw.jpg',
                            newspage:'Dayalan Siva',
                            pagelink:'https://www.youtube.com/watch?v=3lKXrXoUieA',
                            category:"Videos",
                         },
                        ]
                        const categories = ["Articles","Videos"]
    return(
        <div>
            <Navbar />
            <div className="flex flex-col gap-4 mt-8" >
            <div className="flex items-center justify-between px-5 md:px-20"><p className="md:text-4xl text-xl sm:text-lg font-semibold text-sky curvy-underline">Articles</p>
    <div className="flex flex-col">                     
    <button className="dropdown-toggle p-1 md:w-56 w-40 bg-highlight text-white" onClick={() => setDropdownOpen(!dropdownOpen)}>
              {selectedCategory}
              </button>      
              {dropdownOpen && (
                <div className="dropdown-menu absolute flex flex-col items-start z-10 w-56 mt-8 bg-white border border-gray-300 mt-1 py-1 px-2">
                  {categories.map((category, index) => (
                    <div className="">
                    <button className="hover:text-pink" key={index} onClick={() => { handleCategoryChange(category); setDropdownOpen(false) }}>
                      {category}
                    </button>
                    </div>
                  ))}
                </div>
              )}
              </div> 
    </div>
            <div className='flex flex-wrap gap-16 px-3 md:gap-20  md:px-20 py-10'>
            {data.filter((event)=> event.category === selectedCategory).map((event)=>(
    <Link to={event.pagelink} target="_blank" rel="noreferrer">
    <div key={event.id} className='flex flex-col border border-gray-200 h-max w-[20rem] rounded-md md:w-[22rem]'>
<div className="relative">
<img className="w-full md:h-64" src={event.img} alt="" />
<div className="bg-pink absolute bottom-0 text-white rounded-r-full w-max px-2 py-1 flex justify-center">
    <span className=" uppercase text-sm font-bold">{event.newspage}</span>
        </div>
</div>
    <div className={`flex flex-col gap-3 ${event.category === "Articles" ? "py-3":""}`}>
      <div className='flex justify-between items-start'>
    <div className='flex items-start w-full flex-col gap-2'>
        <div className="flex flex-col  w-full">
        {event.category==="Videos"?<button className="border py-1 text-highlight hover:bg-highlight hover:text-white flex i justify-center-center rounded-b-md border-gray-300 w-full"><span className="flex items-center w-full gap-2 justify-center"><Icon className="w-5 h-5" icon="solar:play-circle-line-duotone" />Watch Video</span></button>:""}
        <span className='text-lg font-semibold  line-clamp-2'>{event.newsheading}</span>
        <span className="text-pink text-sm font-semibold">{event.date}</span>
        <span className='text-sm line-clamp-5'>{event.newsdetails}</span>
        </div>
        </div>
    </div>

    <div className="">

    </div>
 </div>
 </div>
 </Link>
    ))}

        </div>
            </div>
        </div>
    )
}

export default ArticlesPage;