import React from "react";
import arrowRight from '@iconify/icons-mdi/arrow-right';
import { Icon } from '@iconify/react';
import { NavLink } from "react-router-dom";

const Artwork = () => {
  const data = [{
    id:1,
    heading:'Sri Narayanaguru and the Coconut Tree',
    desciption:"Shri Narayana Guru's teachings and thoughts are akin to the herbs that grow on the mountains of Kerala; the latter are used to treat diseases of the body, the former are a remedy for regressive thought and other diseases of the mind. The coconut tree is the most largely grown crop in Kerala, for the whole plant, i.e each of it's individual parts has many uses and benefits to humans. Guru, who is widely considered the greatest social reformer from Kerala, like the coconut tree, devoted his entire lifetime to instilling progressive thought and action, for the betterment of society. Guru and the Coconut have a relationship that goes beyond this simple parallel. He was born into a family that made and sold coconut toddy. It was, in fact, a head injury that he sustained when a coconut fell on him that led to his death. This work is a study of this very relationship.",
    img:'port1.JPG'
  },
  {
    id:2,
    heading:'Guru - 360',
    desciption:"God, spirituality, nature, philosophy etc. manifest in that way depending on how we approach it. If we look at anything superficially, they will not be visible to us properly. When we approach it with deep engagement, we can feel the theme/truth in it. This sculpture shares such an experience. I was very impressed by the references to late Sri Narayanan Guru, the great reformer and spiritualist of Kerala. His work has revolutionized the knowledge base of Kerala. I created this sculpture as a result/record of realizing that he permeates all platforms through his activities.",
    img:'port2.jpg'
  }]
  return (
    <div className="min-h-screen flex flex-col gap-4">
        <div className="flex items-center justify-between px-5 md:px-40"><p className="md:text-4xl text-xl font-semibold md:font-bold curvy-underline">ArtWorks</p><NavLink to='/artworks'><p className="flex items-center gap-2 hover:text-pink text-sm cursor-pointer">See all<span className="p-1 rounded-full border text-pink border-pink"><Icon icon={arrowRight} /></span></p></NavLink></div>
      {data.map((artwork,index)=>(
      <div key={artwork.id} className={`flex items-center flex-col px-5 md:gap-0 gap-4 justify-center flex-wrap ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'}`}>
      <div className="w-full md:w-1/3 md:p-10">
        <img src={artwork.img} alt="Artwork 1" className="w-full" />
      </div>
      <div className="w-full md:w-1/2 md:p-10 flex flex-col gap-1 md:gap-4">
      <h1 className="md:text-4xl text-sm font-semibold">{artwork.heading}</h1>
        <p className=" md:text-lg text-xs text-left">
       {artwork.desciption}
        </p>
      </div>
    </div>
      ))}
    </div>
  );
};

export default Artwork;
