import React from "react";
import Navbar from "../components/navbar";
import { Link } from "react-router-dom";
const exhibitions = [
  {
    id: 1,
    title: "Vaanam Art Festival",
    place: '2024, Chennai',
    description: `Humankind's collective consciousness holds stable, eternal,
                  permanent images. One can admire them for hours, calmly observing
                  their inimitable perfection. But what if these images looked back
                  at us? What would they see in our swiftly changing, weak,
                  faltering civilization? Would they think that humankind was
                  hurtling towards a precipice? Ever since the Renaissance, our era
                  has been steeped in the legacy of the Classical Antiquity, and our
                  civilizations have a lot in common. But we have achieved more:`,
    image: "vaanam24.JPG"
  },
  {
    id: 2,
    title: "The Rooting Nails",
    place: '2023, New Delhi',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "exhibit.jpg"
  },
  {
    id: 3,
    title: "Vaanam Art Festival",
    place: '2023, Chennai',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "vaanam.jpg"
  },
  {
    id: 4,
    title: "Fault Lines",
    place: '2023, Gujarat',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "thinaivaasigal.JPG"
  },  
  {
    id: 5,
    title: "Thinai Vaasigal",
    place: '2023, Kerala',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "thinaivaasigal.JPG"
  },
  {
    id: 6,
    title: "Dalit Aesthetics",
    place: '2022, Chennai',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "dalitaesthetics.JPG"
  },
  {
    id: 7,
    title: "Vaanam Art Festival",
    place: '2022, Chennai',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "vaanam22.jpg"
  },
  {
    id: 8,
    title: "Art For Hope",
    place: '2022, Delhi',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "exhibition6.jpg"
  },
  {
    id: 9,
    title: "Chennai Photo Biennale",
    place: '2021, Chennai',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "exhibit1.jpg"
  },
  {
    id: 10,
    title: "Emerging Canvas",
    place: '2021, Inko Centre, Korea and India',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "exhibit1.jpg"
  },
  {
    id: 11,
    title: "In Search Of The Story Of A Harbour",
    place: '2020, Kerala',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "exhibit3.jpg"
  },  
  {
    id: 12,
    title: " CUSP Art Festival",
    place: '2020, Chennai',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "exhibit3.jpg"
  },  
  {
    id: 13,
    title: "Uru Art Harbour",
    place: '2019, Kerala',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "exhibit3.jpg"
  },
  {
    id: 14,
    title: "Perch Theatre group Exhibition",
    place: '2019, Chennai',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "takeflight.jpg"
  },
  {
    id: 15,
    title: "Madras Art Collective",
    place: '2019, Chennai',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "madrasartcollective.JPG"
  },
  {
    id: 16,
    title: "Archiving Labour Library",
    place: '2019, New Delhi',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "exhibit3.jpg"
  },
  {
    id: 17,
    title: "Goa Serendipity Art Festival",
    place: '2018, Goa',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "exhibit3.jpg"
  },
  {
    id: 18,
    title: " Students Biennale ",
    place: '2017, Kerala',
    description: `Industrialization and globalization have reached all corners of the
                  globe, our wars are ever more destructive, and the planet is
                  cracking under our pressure. We live in the consumerist era, and
                  this might be our downfall. Our civilization’s collapse might
                  prove more cataclysmic than that of any ancient culture.`,
    image: "exhibit3.jpg"
  },
];

const ExhibitionPage = () => {
  return (
    <div className="min-h-screen flex flex-col gap-4">
      <Navbar />
      <div className="flex items-center justify-center px-10">
        <p className="titleheading text-center curvy-underline">Exhibitions</p>
      </div>
      {exhibitions.map((exhibition, index) => (
  <Link to={`/exhibitions/${exhibition.id}`}><div key={exhibition.id} className={`flex items-center justify-center md:gap-4 flex-wrap ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'}`}>
              <div className="w-full md:w-1/4 px-10 md:p-10 animate-slideUp">
                <img src={exhibition.image} alt={`Artwork ${index + 1}`} className="custom:w-full xl:h-80 xl:w-full sl:h-64 sl:w-full " />
              </div>
              <div className="w-full md:w-1/4 p-10 flex flex-col gap-4 animate-slideUp">
                <div>
                <h1 className="xl:text-2xl sl:text-lg font-semibold">{exhibition.title}</h1>
                <p className="text-md text-pink font-semibold">{exhibition.place}</p>
                </div>
                <p className=" text-sm md:text-left">
                  {exhibition.description}
                </p>
              </div>
              </div>
              </Link>
      ))}
    </div>
  );
};

export default ExhibitionPage;
