import React,{useState} from "react";
import Modal from 'react-modal';
import Navbar from "../components/navbar";
import { useSelector,useDispatch } from "react-redux";
import { removefromCart } from "../slices/cartslice";
import { Icon } from "@iconify/react/dist/iconify.js";

const Cart = () => {
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.cartItems);
    const total = cartItems.map(cart=>cart.price*cart.quantity).reduce((prev,next)=>prev+next,0)
    const[popup,setPopup] = useState(false)

    const handleOpenPopup = ()=>{
        setPopup(!popup)
    }

    const handleClosePopup = ()=>{
        setPopup(false)
    }
    return(
        <div className="flex min-h-screen relative flex-col gap-4">
            <Navbar />
            <div className="flex justify-center">
            <p className="text-4xl font-semibold text-sky curvy-underline">Cart</p>
            </div>
            {cartItems.length===0 && <div className="flex items-center justify-center"><p className="text-3xl">Cart is Empty!</p></div>}
            <div className="relative flex  bg-black flex-col">
            <div className="overflow-auto">
                {cartItems?.map((cart) =>{
    return(
<div class="flex flex-col">
  <div class="overflow-auto">
    <div class="flex xl:flex-row justify-between border-gray-300 border-b bg-gray-100 items-center w-full p-8">
      <div class="xl:flex items-center gap-6">
        <img class="w-28 h-28 md:w-40 md:h-40" src={cart.image} alt={cart.name} />
        <p class="font-semibold md:text-xl sm:w-40">{cart.name}</p>
      </div>
      <p class="text-md ">{cart.price}</p>
      <button class="hover:text-red" onClick={()=>{dispatch(removefromCart(cart.id))}}><Icon class="w-6 h-6" icon="ic:outline-close" /></button>
    </div>
  </div>
</div>
                    )
                })}
                </div>
            </div>
           {cartItems.length>0 && <div className="fixed flex justify-end bg-white py-2 bottom-0 w-full"><button onClick={handleOpenPopup} className="bg-black py-2 right-20 text-white w-52 rounded-sm ">Checkout</button></div>}
           <Modal isOpen={popup} onRequestClose={handleClosePopup} style={{
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      backgroundColor:'white',
      transform: 'translate(-50%, -50%)',
      width: '40%',
      height: '50%',
      overflowY: 'auto',
      border:'none',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
    },
  }}>
    <div>
    <div className="flex justify-between ">
                <div></div>
                <Icon className="right-0 top-0 hover:text-red h-6 w-6 cursor-pointer" onClick={handleClosePopup} icon="mingcute:close-line" />
            </div>
            <div className="flex flex-col items-center justify-center">
            <form className="w-1/2 flex flex-col h-96 gap-6 items-center justify-center">
            <div className="flex flex-col  w-full">
            <p className="text-xl font-semibold">Customer Details</p>
            </div>
            <div className="flex flex-col w-full gap-4">
            <input className="h-11 rounded-md border border-gray-150 focus:border-pink-500 px-4" required placeholder="Your Name *" />
            <input className="h-11 rounded-md border  border-gray-150 px-4" placeholder="Your Phone number *" required />
            <input className="h-11 rounded-md border   border-gray-150 px-4" placeholder="Your Email" />
            <button className="bg-white p-2 rounded-md font-semibold border border-pink text-pink hover:bg-pink hover:text-white">Send</button>
            </div>
            </form>
            </div>
            </div>
</Modal>
        </div>
    );
};

export default Cart;
