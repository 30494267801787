import React, { useState } from "react";
import Navbar from "../components/navbar";
import Modal from 'react-modal';
import { Icon } from "@iconify/react/dist/iconify.js";
import { useDispatch,useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Addtocart } from "../slices/cartslice";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useSwipeable } from 'react-swipeable';
import ReactPlayer from 'react-player';

const Artgallery = () => {
  const {cartItems} = useSelector((state)=>state.cart);
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [openPopup,setOpenPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [readMore,setReadMore] = useState(false);

  const HandleReadmore = () => {
      setReadMore(!readMore);
  };

  const handleOpenPopup = (image) => {
    setSelectedImage(image);
    setOpenPopup(true);
  };
  const handleClosePopup = ()=>{
    setOpenPopup(false)
    setReadMore(false)
  }

  const data = [{
    id:1,
    img:'art1.JPG',
    heading:'NAMMALWAR',
    price:'',
    images: [
      { src: 'art1.JPG', alt: 'Art 1 Image 1' },
      { src: 'art1-1.JPG', alt: 'Art 1 Image 2' },
      { src: 'art1-2.JPG', alt: 'Art 1 Image 3' }
    ],    
    material:'Clay model | 2 Feet',
    description:'Throughout his life he spoke to people all over Tamil Nadu about organic farming. He was concerned about nature and organic farming.He used to create awareness among the people about contemporary corporate politics and the destruction of agriculture due to it. he fought without compromise till the end.',
    category:'Sculptures'
},{
    id:2,
    img:'art2.JPG',
    heading:'IN THE FUTURE FORESTS WILL REMAIN ONLY MEMORIES.',
    material:'Mixed media | 8x4 Feet',
    price:'',
    description:'Human-made governments, religions, scientific development, globalization, liberalism and wars between world nations have occupied this earth. Due to this nature is getting destroyed day by day. This artwork conveys it.',
    category:'Sculptures'
},{
    id:3,
    img:'art3.jpg',
    price:'',
    heading:'IN SEARCH OF THE STORY OF A HARBOUR -1',
    material:'Etching on Wood |14x10x10',
    description:"Throughout Kochi, I have seen many myriad distorted historical symbols. The imagination that they instilled in me was a wonderful experience. This work is antadaptation to transmit that experience/ occurrence to the viewer. This work is a documentation to create stories or histories that are close to reality from the existing archives or images as per one's convenience.",
    category:'Sculptures'
},{
    id:4,
    img:'art4.JPG',
    price:'',
    heading:'JOURNAL OF CLOTH AND STONE - II',
    images: [
      { src: 'art4.JPG', alt: 'Art 1 Image 1' },
      { src: 'art6.JPG', alt: 'Art 1 Image 2' },
    ],  
    material:'Burn and Pen darwing on plywood | 8x4 Feet',
    description:'Through the portrait drawings, I have documented the people I met and interacted with from the washerman community in Kochi.',
    category:'Paintings'
},{
    id:5,
    img:'art5.JPG',
    price:'',
    heading:'La CONNECTED',
    material:'Pen on Paper | 24" x 18" ',
    description:'This Art work is about the realisation that any labour in this world is always connected to the different community people and their activities.',
    category:'Paintings'
},{
  id:6,
  img:'ambed.png',
  price:'',
  images: [
    { src: 'ambed.png', alt: 'Art 1 Image 1' },
    { src: 'ambed1.png', alt: 'Art 1 Image 2' },
    { src: 'ambed2.png', alt: 'Art 1 Image 3' },
  ],  
  heading:'Revolutionary Book',
  material:'Pen on Paper | 24" x 18" ',
  description:"I was amazed to learn about the revolutionary DR.B.R.Ambedkar's love for books and the time he spent with the books. This sculpture is an expression of the inspiration towartds him",
  category:'Sculptures'
},{
    id:7,
    img:'art7.JPG',
    price:'',
    heading:'La CONNECTED',
    material:'Pen on Paper | 24" x 18" ',
    description:'This Art work is about the realisation that any labour in this world is always connected to the different community people and their activities',
    category:'Paintings'
},{
    id:8,
    img:'art8.JPG',
    price:'',
    heading:'MUTED SERIES',
    material:'Etching on Parai(Cow skin) | 12inch Diameter',
    description:'This art work is about the voiceless, working people of Indian society. It is an attempt to give voice to marginalized people. A pioneer of Tamil traditional musical instruments, this parai (trums) instrument can produce a lot of noise If painting is done on such a leather tool, it loses its character. This art work attempts to convey the strangeness of the subject of painting becoming the sound of a musical instrument',
    category:'Installation Artwork'
},{
    id:9,
    img:'art10.jpg',
    price:'',
    heading:'MALABAR MANUAL - ||',
    images: [{src:'art10.jpg', alt:'art10.jpg'},{
      src:'art11.JPG', alt:'art11.JPG'
    }],
    material:'Terracota and Wood 48x36 inches ',
    description:'The entrance doors of export-import warehouses in Kochi (Mattanjari) and Kozhikode areas are mostly of similar design. These doors are a testament to the past 600 years of trade history. Here This work of art (doors and nose terracotta sculpture) tries to describe the references of people from different countries with trade relations then and now.',
    category:'Sculptures'
},{
    id:11,
    img:'art12.jpg',
    price:'',
    heading:'MALABAR MANUAL - |||',
    material:'Nylon thread,Spice and Wood 36x24 inches ',
    description:'The entrance doors of export-import warehouses in Kochi (Mattanjari) and Kozhikode areas are mostly of similar design. These doors are a testament to the past 600 years of trade history. Here This work of art (doors and nose terracotta sculpture) tries to describe the references of people from different countries with trade relations then and now.',
    category:'Sculptures'
},{
    id:12,
    img:'art13.jpg',
    price:'',
    heading:'KOCHI EXPERIENCE – March 2019 To February 2020',
    material:'Pen on Paper 105x68 inches',
    description:'This work is an attempt to document the experiences and learnings gained during the one-year period of the URU Art Harbor Residency Program in Kochi.',
    category:'Paintings'
},{
    id:13,
    img:'art15.JPG',
    price:'',
    material:'Pen on Paper | 24" x 18" ',
    description:'This Art work is about the realisation that any labour in this world is always connected to the different community people and their activities.',
    heading:'La CONNECTED',
    category:'Paintings'
},{
    id:14,
    img:'art16.JPG',
    price:'',
    heading:'La CONNECTED',
    material:'Pen on Paper | 24" x 18" ',
    description:'This Art work is about the realisation that any labour in this world is always connected to the different community people and their activities.',
    category:'Paintings'
},{
    id:15,
    img:'art17.JPG',
    price:'',
    material:'Pen on Paper | 24" x 18" ',    
    heading:'La CONNECTED',
    description:'This Art work is about the realisation that any labour in this world is always connected to the different community people and their activities.',
    category:'Paintings'
},{
  id:16,
  img:'port3.JPG',
  price:'',
  images: [
    { src: 'port3.JPG', alt: 'Art 1 Image 1' },
    { src: 'port3-1.JPG', alt: 'Art 1 Image 2' },
    { src: 'port3-2.JPG', alt: 'Art 1 Image 3' }
  ], 
  heading:'MALABAR MANUAL - I',
  material:'Spice and Wood | 30"x18"x14"',
  description:'Hundreds of years ago people from Jews to Chinese, Romans, Portuguese, Dutch, British and countless other countries of the world came to India because of the spices and aromatics grown in this land. Its history begins especially from the coastal areas of Kerala-Cochin, Kozhikode Traders who came here in search of these spices are the main reason for the formation of the union that is India today. We know that this Trade continues today. Spices are an important part of this great history. The nose is common to all who have come in search of spices. This is an important component. Based on that I made and registered this nose wood sculpture.',
  category:'Sculptures'
},{
  id:17,
  img:'port1.JPG',
  price:'',
  material:'Terracora | 14"x12"x12"',
  heading:'Sri Narayana Guru and the Coconut Tree',
  description:"Shri Narayana Guru's teachings and thoughts are akin to the herbs that grow on the mountains of Kerala; the latter are used to treat diseases of the body, the former are a remedy for regressive thought and other diseases of the mind. The coconut tree is the most largely grown crop in Kerala, for the whole plant, i.e each of it's individual parts has many uses and benefits to humans. Guru, who is widely considered the greatest social reformer from Kerala, like the coconut tree, devoted his entire lifetime to instilling progressive thought and action, for the betterment of society. Guru and the Coconut have a relationship that goes beyond this simple parallel. This work is a study of this very relationship.",
  category:'Sculptures'
},{
    id:18,
    img:'art18.jpg',
    price:'',
    heading:'MUTED SERIES',
    material:'Etching on Parai(Cow skin) | 12inch Diameter',
    description:'This art work is about the voiceless, working people of Indian society. It is an attempt to give voice to marginalized people. A pioneer of Tamil traditional musical instruments, this parai (trums) instrument can produce a lot of noise If painting is done on such a leather tool, it loses its character. This art work attempts to convey the strangeness of the subject of painting becoming the sound of a musical instrument',
    category:'Installation Artwork'
},{
    id:19,
    img:'art19.jpg',
    price:'',
    heading:'STRUGGLE',
    material:'Carving on Wood 20x14 Inch',
    description:'While continuing to work as an artist, I face many challenges and crises. This work is an expression of that.',
    category:'Sculptures'
},{
    id:20,
    img:'art20.JPG',
    price:'',
    material:'Acrylic on Canvas 3x2 Feet',
    heading:'NOISE',
    description:'While continuing to work as an artist, I face many challenges and crises. This work is an expression of that.',
    category:'Paintings'
},{
    id:21,
    img:'art21.jpg',
    price:'',
    material:'Stone | 16"x8"x8"',
    heading:'7 Stone',
    description:'My parents were stone breakers in a stone quarry. When I was little, in Calquary where my parents worked, we would run and play with my friends on holidays. The most played game is "7 Stone". This stone sculpture was created as a record of my childhood memories. This stone sculpture is carved from stone taken from the same stone quarry where my parents worked. It is noteworthy that this sculpture is carved from a single stone.',
    category:'Sculptures'
},{
    id:22,
    img:'art22.jpg',
    price:'',
    heading:'Crisis for love',
    material:'Baloon and Metal | 5"X3"X3"',
    description:'It is regrettable that there is not much discussion and dissemination on the crisis of love/love within the caste/religious communityy in India. This artwork is an attempt to induce such a conversation within the community',
    category:'Installation Artwork'
},{
    id:23,
    img:'port2.jpg',
    price:'',
    images:[    { src: 'port2.jpg', alt: 'Art 1 Image 1' },
      { src: 'port2-1.jpg', alt: 'Art 1 Image 2' },],
    material:'Stone | 13x12x12',
    heading:'GURU - 360',
    description:'God, spirituality, nature, philosophy etc. manifest in that way depending on how we approach it. If we look at anything superficially, they will not be visible to us properly. When we approach it with deep engagement, we can feel the theme/truth in it. This sculpture shares such an experience. I was very impressed by the references to late Sri Narayanan Guru, the great reformer and spiritualist of Kerala. His work has revolutionized the knowledge base of Kerala. I created this sculpture as a result/record of realizing that he permeates all platforms through his activities.',
    category:'Sculptures'
},{
    id:24,
    img:'art15.JPG',
    price:'',
    material:'Pen on Paper | 24" x 18" ',
    heading:'La CONNECTED',
    description:'This Art work is about the realisation that any labour in this world is always connected to the different community people and their activities.',
    category:'Paintings'
},{
    id:25,
    material:'Fibre | 3"x2 1/2x2"',
    price:'',
    heading:'Dr. B. R. AMBEDKAR LIBRARY',
    description:"Of all my artistic activities, the one I am most proud of is the establishment of this library. Ever since my childhood, I had an ambition to set up a library for students in my village. To accomplish that, I received donations of 1600 books from many friends on Facebook and some of my close friends. I collected more than 400 books that I had. Then Dr. On April 14, 2021, Ambedkar's birthday, I arranged to open the library like a festival. I did the same. I started doing this as a work of art. That's why the excitement never waned",
    img:'exhibit.jpg',
    category:'Installation Artwork'
},{
    id:26,
    img:'exhibit1.jpg',
    price:'',
    heading:'A KEEZHADI',
    material:'Cyanotype Print and Photographs',
    description:'Even as the Keeladi excavations make headlines for having re-shaped the anicent history of Tamils in the subcontinent,it shifts the lens from the larger narrative to re-imagining the site as the historical inherence of the local communities, whose lands hold within them stories of their ancestors. Employed as labour at the sites, they are the first witnesses to the unearthing of history, yet remain largely invisible in the historical framing of the site. This work is as much a tribute to them as it is a commentary on the blind spots in archeological knowledge',
    category:'Installation Artwork'
}
,{
  id:27,
  img:'art23.jpg',
  price:'',
  heading:'Guru-vanam',
  material:'Mixed media 3x1/2 Feet',
  description:'This painting is about my mentor, painter Chandru Guruswamy and the open air museum he created',
  category:'Paintings'
},{
  id:28,
  img:'exhibit5.JPG',
  price:'',
  material:'Video',
  heading:'OUR DAYS WITH OUR MUSIC',
  description:'A photo documentary about the quarry and the quarry workers who work there.',
  category:'Video Artwork',
  link:"https://drive.google.com/file/d/1iq7vrtwNBaEJ_yG9iMkfynoFEIgafsZa/view?usp=sharing"
},{
  id:29,
  img:'exhibitr.JPG',
  price:'',
  heading:'JOURNAL OF CLOTH AND STONE - I',
  material:'Mixed media | 68x8x6 Feet',
  description:'This artwork was created with the intention of documenting the conversation and time spent with laundry workers in Kochi (Dopikana). I observed that the workers not only wash clothes but also have their own ideology about everything around them. The most important words that impressed me in my conversation with them are written on these cloths. This conversation has given me a new perspective. I have tried to convey this through this work.',
  category:'Installation Artwork'
},{
  id:30,
  img:'videoart.JPG',
  price:'',
  heading:'OLI - NIZHAL NADUVIL NAANGAL',
  material:'Video',
  description:'For the past seven years, Tamil Nadu archeology department has been excavating in keezhadi village of Sivagangai district.Keezhadi excavation is considered one of the prides of the Tamil community. All the workers working in Keezadi archaeological zare natives of Keezhadi and adjoining villages. Their role in excavation work is important. This film is an attempt to document their day-to-day work and excavation work at the Keezadi excavation site from 9 am to 5 pm',
  category:'Video Artwork',
  link:'https://drive.google.com/file/d/11IPZ_PaX_8oyoMTeH4V_ab9cvniJwXOg/view?usp=sharing'
},
{
  id:31,
  img:"avatar.JPG",
  price:'',
  images:[
    { src: 'avatar.JPG', alt: 'Art 1 Image 1' },
    { src: 'avatar1.JPG', alt: 'Art 1 Image 2' },
    { src: 'avatar2.JPG', alt: 'Art 1 Image 3' }
  ],
  material:'Bamboo sticks and Sand',
  heading:'Avatar',
  description:'Trapped inside various superstitions like caste,religion,rituals and clan pride, they lose love and compassion during their livelihood. The supernatural does not exists in this world.This work shows that nothing can be challenged in front of nature',
  category:'Installation Artwork'
},
{
  id:32,
  img:"bronze360.jpg",
  price:'',
  material:'Bronze',
  heading:'GURU 360',
  description:'God, spirituality, nature, philosophy etc. manifest in that way depending on how we approach it. If we look at anything superficially, they will not be visible to us properly. When we approach it with deep engagement, we can feel the theme/truth in it. This sculpture shares such an experience. I was very impressed by the references to late Sri Narayanan Guru, the great reformer and spiritualist of Kerala. His work has revolutionized the knowledge base of Kerala. I created this sculpture as a result/record of realizing that he permeates all platforms through his activities.',
  category:'Sculptures'
},
{
  id:33,
  img:"wooden360.jpg",
  price:'',
  material:'Wood',
  heading:'GURU 360',
  description:'God, spirituality, nature, philosophy etc. manifest in that way depending on how we approach it. If we look at anything superficially, they will not be visible to us properly. When we approach it with deep engagement, we can feel the theme/truth in it. This sculpture shares such an experience. I was very impressed by the references to late Sri Narayanan Guru, the great reformer and spiritualist of Kerala. His work has revolutionized the knowledge base of Kerala. I created this sculpture as a result/record of realizing that he permeates all platforms through his activities.',
  category:'Sculptures'
},
{
  id:34,
  img:"gift.jpg",
  price:'',
  material:'24"x18"',
  heading:'Gift/God',
  description:'Trapped inside various superstitions like caste,religion,rituals and clan pride, they lose love and compassion during their livelihood. The supernatural does not exists in this world.This work shows that nothing can be challenged in front of nature',
  category:'Installation Artwork'
}]
const item={
id:selectedImage?.id,
price:selectedImage?.price,
name:selectedImage?.heading,
image:selectedImage?.img,
category:selectedImage?.category
}

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    console.log(selectedCategory)
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => console.log('swiped left'), // You can handle swipe left if needed
    onSwipedRight: () => console.log('swiped right') // You can handle swipe right if needed
  });

  const categories = ["All", "Paintings", "Sculptures", "Installation Artwork","Video Artwork"];
  return (
    <div className="bg-white">
      <Navbar />
      <div className="flex items-center justify-center p-5">
            <p className="md:text-4xl text-xl font-semibold text-sky curvy-underline">ArtWorks</p>
          </div>
      <div class="relative h-[39rem] w-full bg-blck mb-4">
      <ReactPlayer
        url="video.mov"
        playing={true}
        loop={true}
        muted={true}
        playsinline={true}
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
</div>
      <div className="flex flex-col items-center justify-center">
        <div className="w-full max-w-6xl overflow-auto">
    <div {...handlers} className="category-scroll-container ">
    <div className="category-scroll-container">
  {categories.map((category) => (
    <button key={category}
      onClick={() => handleCategoryChange(category)}
      className={`flex-shrink-0 md:w-auto md:py-1 px-2 rounded-md cursor-pointer ${selectedCategory === category ? 'bg-pink text-white' : 'bg-white text-pink'} border border-pink`}>
      {category}
    </button>
  ))}
</div>
    </div>
          <div className="w-full max-w-6xl p-5 pb-10 mx-auto mb-10 gap-5 columns-2 md:columns-3 space-y-5">
            {data.filter((gallery) => selectedCategory === "All" || gallery.category === selectedCategory)
              .map((gallery) => (
                <div className="relative overflow-hidden group" key={gallery.id} onClick={() => handleOpenPopup(gallery)}>
                  <img className="image cursor-pointer rounded-md object-cover transition-transform duration-300 transform scale-100 group-hover:scale-110" src={gallery.img} alt={gallery.img} />
                </div>
              ))}
          </div>
        </div>
      </div>
      <Modal isOpen={openPopup} onRequestClose={handleClosePopup} style={{
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    backgroundColor: 'white',
    transform: 'translate(-50%, -40%)',
    width: '90%',
    maxWidth: '90%',
    height: '70%', 
    maxHeight: '90vh', 
    overflowY: 'auto',
    border: 'none',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
  },
}}>
  {selectedImage && (
    <div className="flex flex-col gap-4 p-4 md:p-8">
      <div className="flex justify-between">
        <div></div>
        <Icon className="right-0 top-0 hover:text-red h-6 w-6 cursor-pointer" onClick={handleClosePopup} icon="mingcute:close-line" />
      </div>
      <div className="md:flex items-start justify-between">
      {selectedImage?.images?.length > 0 ? (
  <Carousel className=" md:w-96" infiniteLoop={true} showIndicators={false} showThumbs={false} autoPlay={true} interval={5000} showStatus={false}>
    {selectedImage.images.map((image, index) => (
      <div className="md:w-96" key={index}>
        <img className="h-64 md:h-[28rem]" src={image.src} alt={image.alt}  />
      </div>
    ))}
  </Carousel>
) : (<img className="md:w-96 md:h:96" src={selectedImage.img}/>)}
 <div className="w-full md:w-1/2 flex flex-col gap-4">
          <div className="flex items-center justify-between">
            {selectedImage.category !== "Installation Artwork" && selectedImage.category !== "Video Artwork" ? (
              cartItems.find(item => item.id === selectedImage.id) ? (
                <div><span className="font-semibold">Sold out</span></div>
              ) : (
                <button onClick={() => { console.log(dispatch(Addtocart(item))); handleClosePopup() }} className="relative border-2 border-pink bg-transparent md:w-max w-full rounded-md px-2 py-1 font-medium text-pink transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-pink before:transition-transform before:duration-300 before:content-[''] hover:bg-pink hover:text-white before:hover:scale-x-100">Add to cart</button>
              )
            ) : ""}
            {selectedImage.category === "Video Artwork" && (
              <div>
                <NavLink to={selectedImage.link} target="_blank" rel="noreferrer">
                  <button className="relative border-2 border-pink bg-transparent w-max rounded-md px-2 py-1 font-medium text-pink transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-pink before:transition-transform before:duration-300 before:content-[''] hover:bg-pink hover:text-white before:hover:scale-x-100">Watch</button>
                </NavLink>
              </div>
            )}
          </div>
          <p className="text-3xl uppercase font-semibold">{selectedImage.heading}</p>
          <p>{selectedImage.material}</p>
          <div>
            {readMore ? <p>{selectedImage.description}</p> : <p className="line-clamp-4">{selectedImage.description}</p>}
            {!readMore && (
              <span className="font-semibold cursor-pointer" onClick={() => HandleReadmore(selectedImage)}>Read more</span>
            )}
          </div>
          <p>{selectedImage.price}</p>
        </div>
      </div>
    </div>
  )}
</Modal>
    </div>
  );
};

export default Artgallery;