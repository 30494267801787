import React from "react";
import Navbar from "../components/navbar";
import Carouselo from "../components/carousel";
import Artwork from "../components/artworks";
import Exhibitions from "../components/artexhibitions";
import Artist from "../components/aboutartist";
import Events from "../components/events";

const Home = ()=>{
    return(
        <div className="flex flex-col gap-12">
            <Navbar />
            <Carouselo />
            <Artwork />
            <Exhibitions />
            <Events />
            <Artist />
        </div>
    )
}

export default Home;