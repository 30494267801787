import React from 'react';
import './styles.css'
import './components/navbar'
import Home from './pages/home';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Artgallery from './pages/artworkgallery';
import Aboutpage from './pages/aboutartistpage';
import ExhibitionPage from './pages/exhibitionpage';
import ArticlesPage from './pages/articlespage';
import Awards from './pages/awards';
import Contactus from './pages/contactus';
import Cart from './pages/cart';
import Events from './pages/events';
import EventDetails from './pages/eventdetails';
import ExhibitionDetails from './pages/exhibitiondetails';

const App = () => {
  const exhibitions = [
    {
      id: 1,
      title: "Vaanam Art Festival",
      place: '2024, Chennai',
      description: `Humankind's collective consciousness holds stable, eternal,
                    permanent images. One can admire them for hours, calmly observing
                    their inimitable perfection. But what if these images looked back
                    at us? What would they see in our swiftly changing, weak,
                    faltering civilization? Would they think that humankind was
                    hurtling towards a precipice? Ever since the Renaissance, our era
                    has been steeped in the legacy of the Classical Antiquity, and our
                    civilizations have a lot in common. But we have achieved more:`,
      image: "vaanam24.JPG"
    },
    {
      id: 2,
      title: "The Rooting Nails",
      place: '2023, New Delhi',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "exhibit.jpg"
    },
    {
      id: 3,
      title: "Vaanam Art Festival",
      place: '2023, Chennai',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "vaanam.jpg"
    },
    {
      id: 4,
      title: "Fault Lines",
      place: '2023, Gujarat',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "thinaivaasigal.JPG"
    },  
    {
      id: 5,
      title: "Thinai Vaasigal",
      place: '2023, Kerala',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "thinaivaasigal.JPG"
    },
    {
      id: 6,
      title: "Dalit Aesthetics",
      place: '2022, Chennai',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "dalitaesthetics.JPG"
    },
    {
      id: 7,
      title: "Vaanam Art Festival",
      place: '2022, Chennai',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "vaanam24.jpg"
    },
    {
      id: 8,
      title: "Art For Hope",
      place: '2022, Delhi',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "exhibition6.jpg"
    },
    {
      id: 9,
      title: "Chennai Photo Biennale",
      place: '2021, Chennai',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "exhibit1.jpg"
    },
    {
      id: 10,
      title: "Emerging Canvas",
      place: '2021, Inko Centre, Korea and India',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "exhibit1.jpg"
    },
    {
      id: 11,
      title: "In Search Of The Story Of A Harbour",
      place: '2020, Kerala',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "exhibit3.jpg"
    },  {
      id: 12,
      title: " CUSP Art Festival",
      place: '2020, Chennai',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "exhibit3.jpg"
    },  {
      id: 13,
      title: "Uru Art Harbour",
      place: '2019, Kerala',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "exhibit3.jpg"
    },
    {
      id: 14,
      title: "Perch Theatre group Exhibition",
      place: '2019, Chennai',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "takeflight.jpg"
    },
    {
      id: 15,
      title: "Madras Art Collective",
      place: '2019, Chennai',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "madrasartcollective.JPG"
    },
    {
      id: 16,
      title: "Archiving Labour Library",
      place: '2019, New Delhi',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "exhibit3.jpg"
    },
    {
      id: 17,
      title: "Goa Serendipity Art Festival",
      place: '2018, Goa',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "exhibit3.jpg"
    },
    {
      id: 18,
      title: " Students Biennale ",
      place: '2017, Kerala',
      description: `Industrialization and globalization have reached all corners of the
                    globe, our wars are ever more destructive, and the planet is
                    cracking under our pressure. We live in the consumerist era, and
                    this might be our downfall. Our civilization’s collapse might
                    prove more cataclysmic than that of any ancient culture.`,
      image: "exhibit3.jpg"
    },
  ];
  const data = [
    {
      id: 1,
      title: "Old Painting Restore Workshop",
      place: 'Government Museum, Madurai',
      status:'Completed Events',
      category: 'Workshop',
      description: `Industrialization and globalization have reached all corners of the
      globe, our wars are ever more destructive, and the planet is
      cracking under our pressure. 
      ${<br />}
      We live in the consumerist era, and
      this might be our downfall. Our civilization’s collapse might
      prove more cataclysmic than that of any ancient culture.`,          
      image: "event2.jpg"
    },
    {
      id: 2,
      title: "Clay sculpting workshop ",
      status:'Completed Events',
      place: 'American College of Arts and Science, Madurai',
      category: 'Workshop',
      description: `Industrialization and globalization have reached all corners of the
      globe, our wars are ever more destructive, and the planet is
      cracking under our pressure. We live in the consumerist era, and
      this might be our downfall. Our civilization’s collapse might
      prove more cataclysmic than that of any ancient culture.`,          
      image: "event3.JPG"
    },
    {
      id: 3,
      title: " “Parai” Tamil traditional musical instrument making workshop",
      place: 'Kochi Muziris Biennale, Kochi',
      category: 'Workshop',
      status:'Completed Events',
      description: `Industrialization and globalization have reached all corners of the
      globe, our wars are ever more destructive, and the planet is
      cracking under our pressure. We live in the consumerist era, and
      this might be our downfall. Our civilization’s collapse might
      prove more cataclysmic than that of any ancient culture.`,
      image: "event1.jpg"
    },
    {
      id: 4,
      title: " Chennai Photo Biennale: Screening for documentary on keezhadi",
      place: 'Kochi Muziris Biennale, Kochi',
      category: 'Workshop',
      status:'Completed Events',
      description: `Industrialization and globalization have reached all corners of the
      globe, our wars are ever more destructive, and the planet is
      cracking under our pressure. We live in the consumerist era, and
      this might be our downfall. Our civilization’s collapse might
      prove more cataclysmic than that of any ancient culture.`,
      image: "Keezhadi.png"
    },
    
  ];
  return (
    <Router>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/artworks' element={<Artgallery />} />
              <Route path='/about' element={<Aboutpage />} />
              <Route path='/exhibitions' element={<ExhibitionPage />} />
              <Route path='/articles' element={<ArticlesPage />}/>
              <Route path='/awards' element={<Awards />} />
              <Route path='/contactus' element={<Contactus />} />
              <Route path='/cart' element={<Cart />} />
              <Route path='/events' element={<Events />} /> 
              <Route path="/events/:eventId" element={<EventDetails data={data} />} />
              <Route path='exhibitions/:exhibitionId' element={<ExhibitionDetails data={exhibitions} />} />
              </Routes>
    </Router>
  );
}

export default App;

