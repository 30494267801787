import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/navbar';

const ExhibitionDetails = ({ data }) => {
  const { exhibitionId } = useParams();
  const event = data.find(event => event.id === parseInt(exhibitionId));

  if (!event) {
    return <div>Event not found</div>;
  }

  return (
    <div>
      <Navbar />
      <div className='md:flex  items-start justify-between p-5 gap-4 md:p-20'>
        <div className='md:w-1/2 flex flex-col gap-2 mb-4'>
        <h1 className='text-3xl  font-bold'>{event.title}</h1>
        <p className='md:text-lg text-sm text-gray-400 font-semibold'>{event.place}</p>
        <p className='md:text-lg text-xs'>{event.description}</p>
        </div>
        <div className='md:w-1/2 flex items-center justify-end'>
        <img className='md:w-5/6' src={`/${event.image}`} alt={event.image} />
        </div>
        </div>
    </div>
  );
};

export default ExhibitionDetails;
