import React,{useState} from "react";
import Navbar from "../components/navbar";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";

const Contactus =()=>{
    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        query: '',
      });
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axios.post('/submit-form', formData);
          alert('Form submitted successfully');
        } catch (error) {
          console.error('Error submitting form:', error);
          alert('Failed to submit form');
        }
      };
    return(
        <div>
            <Navbar />
        <div className="md:h-[52.8rem] md:w-full md:flex ">
            <div className="md:w-1/2 bg-highlight flex items-center justify-center">
            <form onSubmit={handleSubmit} className="md:w-1/2 flex flex-col gap-6 md:p-0 py-10 items-center justify-center">
            <div className="flex flex-col md:px-0 px-10 w-full">
            <span className="md:text-4xl text-3xl text-white font-bold">Get in <span className="text-pink">Touch</span></span>
            <p className="text-white md:text-md text-sm">Tell us about your queries and we'll contact you as soon</p>
            </div>
            <div className="flex flex-col md:w-full gap-4">
            <input onChange={handleChange} className="h-11 rounded-md border border-gray-50 bg-highlight text-white focus:border-pink-500 px-4" required placeholder="Your Name *" />
            <input type="number" onChange={handleChange} className="h-11 rounded-md border bg-highlight text-white border-gray-50 px-4" placeholder="Your Phone number *" required />
            <input  onChange={handleChange} className="h-11 rounded-md border bg-highlight text-white border-gray-100 px-4" placeholder="Your Email" />
            <textarea  onChange={handleChange} className="h-40 rounded-md border bg-highlight text-white border-gray-100 px-4 py-2" required placeholder="Your Query *" />
            <button className="bg-white p-2 rounded-md font-semibold hover:bg-pink hover:text-white">Send</button>
            </div>
            </form>
            </div>
            <div className="md:w-1/2 md:bg-gradient-to-tr from-span-start to-span-end flex items-center justify-center">
            <div className="w-96 flex flex-col items-start justify-center  gap-4 bg-white md:bg-opacity-20 backdrop-blur-lg backdrop-filter p-8 rounded-lg">
  <div className="flex flex-col gap-0">
    <p className="text-xl text-black font-semibold">Address</p>
    <p className="text-md">1 / 3 5, Ambedkar Street, Karadippatti, <br/>
    Keelakuilkudi-Post, Madurai-625019, <br/>
    Tamil Nadu, India. </p>
  </div>
  <div className="flex flex-col gap-0">
    <p className="text-xl text-black font-semibold">Email</p>
    <p className="text-md">sraj53324@gmail.com </p>
  </div>
  <div className="flex flex-col gap-0">
    <p className="text-xl text-black font-semibold">Phone number</p>
    <p className="text-md">(+91) 9597727479</p>
  </div>
  <div className="flex flex-col gap-2">
    <p className="text-xl text-black font-semibold">Follow on</p>
    <a target="_blank " rel="noreferrer" href="https://www.instagram.com/artist_saranraj/">
      <div className="flex items-center gap-2">
        <Icon className="w-8 h-8 cursor-pointer" icon="skill-icons:instagram" />
        <p className="font-semibold">@artist_saranraj</p>
      </div>
    </a>
  </div>
</div>
            </div>
        </div>
          </div>
    )
}

export default Contactus;