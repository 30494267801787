import React, { useState, useEffect, useRef } from 'react';
import '../styles.css';
import { NavLink } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useSelector } from 'react-redux';

const Navbar = () => {
  const {cartItems} = useSelector((state)=>state.cart)
  const headers = [ 
    { id: 1, title: 'HOME', path: '/' },
    { id: 2, title: 'ARTWORKS', path: '/artworks' },
    { id: 3, title: 'ABOUT', path: '/about' },
    { id: 4, title: 'EXHIBITIONS', path: '/exhibitions' },
    { id: 5, title: 'EVENTS', path: '/events' },
    { id: 6, title: 'ARTICLES', path: '/articles' },
    { id: 7, title: 'AWARDS & RESIDENCY', path: '/awards' },
    { id: 8, title: 'CONTACT US', path: '/contactus' },
  ];

  const [activeSection, setActiveSection] = useState(null);
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveSection(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return(
        <div className='sticky top-0 z-50'>
          <div className='h-16 w-full bg-highlight text-white flex flex-col border-gray-400 border-b items-center justify-center'>
            <p className='font-bold text-xl'>SARAN RAJ</p>
            <span>Artist / Sculptor</span>
          </div>
          <nav className='flex flex-wrap bg-highlight text-white sm:w-full sm:text-md z-10 justify-between items-center h-16 px-5 flex-row'>
          <div className='hidden sm:flex items-center gap-8'></div>
            <div className="sm:hidden">
              <div className="relative">
                <button
                  onClick={handleDropdownClick}
                  className="flex items-center justify-center p-3 rounded-full cursor-pointer duration-500 ease-in-out hover:bg-transparent"
                >
                  <span className="sr-only">Toggle Menu</span>
                  <Icon className='w-6 h-6' icon="ic:baseline-menu" />
                </button>
                {showDropdown && (
                  <div className="absolute left-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-10">
                    <div className="py-1">
                      {headers.map((header) => (
                        <NavLink
                          key={header.id}
                          to={header.path}
                          onClick={() => setShowDropdown(false)}
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                        >
                          {header.title}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="hidden sm:flex items-center justify-center gap-14">
              {headers.map((header) => (
                <div key={header.id}>
                  <NavLink to={header.path}>
                    <span className={`cursor-pointer font-semibold hide-underline`}>{header.title}</span>
                  </NavLink>
                </div>
              ))}
            </div>
            <NavLink to='/cart'>
          <div className='p-3 rounded-full cursor-pointer duration-500 ease-in-out hover:bg-transparent relative'>
            <Icon className='w-6 h-6' icon="ri:shopping-cart-line" />
            {cartItems.length > 0 && (
              <span className="absolute -top-0.5 -right-0.5 bg-pink text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">{cartItems.length}</span>
            )}
          </div>
        </NavLink>
          </nav>
        </div>
      )
    
};

export default Navbar;
