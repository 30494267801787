import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cartItems:[]
}

const Cartslice = createSlice({
    name:'cart',
    initialState,
    reducers:{
        Addtocart(state, action) {
            const newItem = action.payload;
            const existingItem = state.cartItems.find(item => item.id === newItem.id);
            if (!existingItem) {
                state.cartItems.push({ ...newItem, quantity: 1 });
            }
            else {
                window.alert('Item already exists in the cart!');
            }
        },
        removefromCart(state,action){
           state.cartItems = state.cartItems.filter((item)=>item.id !== action.payload)
        }
    }
})

export const {Addtocart,removefromCart} = Cartslice.actions
export default Cartslice.reducer