import React,{useState,useRef,useEffect} from "react";
import Navbar from "../components/navbar";
import { NavLink } from "react-router-dom";

const Aboutpage = () => {
  const [showSlideUp, setShowSlideUp] = useState(false);
  const sectionRef = useRef(null);

  const data=[{
    id:1,
    heading:"Art and political",
    gallery:"Varija Art Gallery",
    description:"Art - Politics” curated by artist Saranraj, explores the autobiographical works of artist Chandrasekaran Guruswamy. The narrative of the exhibition is strongly influenced by the artist’s experiences and thoughts which have been distilled into drawings. 150 of these drawings are on display and more will be released as a book next month.",
    Link:"https://www.youtube.com/watch?v=2LGVRfqqmsM",
    img:"artpolitical.JPG"
  },
  {
    id:2,
    heading:"Thinai Vaasigal",
    gallery:"Art Gallery",
    description:"We're happy and proud as alumni of Government College of Fine Arts, Chennai across the years, to unite as an artist collective. We see the coming together of us artists, or us 'Thinai Vaasigal' as a cultural event. Through our contemporary art practice. we approach to social mores, social hierarchies, interpersonal relationships, and so on",
    img:"thinaicurate.JPG"
  }]

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowSlideUp(true);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div className="relative">
      <Navbar />
      <div className="container mx-auto p-10 gap-14 flex flex-col md:flex-row items-center md:items-center">
        <img src="saranraj.jpeg" alt="About Us" className=" xl:h-[48rem] sl:h-[32rem] md:w-auto rounded-lg" />
        <div className="md:ml-8 mt-4 flex flex-col gap-4 md:mt-0">
          <h2 className="xl:text-4xl sl:text-2xl sl:w-36 text-xl font-semibold curvy-underline w-max xl:w-52">About Artist</h2>
          <p className="custom:text-xl xl:text-2xl sl:text-sm ">
            I come from Karadipatti, a small village in Madurai district, from the southern part of Tamil Nadu and hold a graduate degree in Sculpting from the Government Fine Arts College in Chennai.
             Having been based in Chennai for over 8 years, I have dedicated myself to the practice of creating artworks, with a specialization in sculpture. However, my artistic creations vary significantly depending on the context and the nature of each project, and the medium I choose to work with depends on the theme I aim to express. 
             <br/>Engaged in the study of excavations in Tamil Nadu, I find great interest in utilizing them as a source of inspiration for my creations. Concurrently, I am devoted to the pursuit of re-examining traditional art, skilfully infusing its theoretical and conceptual aspects into contemporary artworks. Through my work I intend to spark a dialogue on the existing indifferences and graded inequality in our society. And through my works I want to make a direct connection with the society especially with the students. I believe that artistic and literary cultural activities will bring about the biggest change in the society. So I try to pass it on to the next generation. In relation to that, in 2021 I have created a library with more than 2000 books in my own village. The Government of Tamil Nadu has developed a program called 'Visual Art & Craft' as a part-time special course for government school students. I have been a member of the curriculum development team and a resource person for the same. I have been a resource person for the training workshops for students called "Pudhiyana Virumbu" conducted by the School Education Department, Government of Tamil Nadu. Apart from this, in March 2023, I also organized and curated Senior artist and ex-principal of Fine Arts College Chandru Guruswamy's painting exhibition titled "Art – Politics," in July 2023.
          </p>
        </div>
      </div>
      <div className="hidden sm:block md:absolute sl:opacity-0 xl:opacity-100 bottom-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-gray-400 w-64 h-32 rounded-full"></div>
      <div className="flex flex-col gap-6">
      <div className="text-center" >
      <h2 className={`xl:text-4xl xl:w-[25rem] sl:text-2xl sl:w-72 text-xl font-semibold curvy-underline w-max ${showSlideUp ? 'animate-slideUp' : ''}`}>Saranraj Curated Shows</h2>
      </div>
      <div className={` flex gap-10 flex-col items-center`}>
        {data.map((curatedshow,index)=>(
      <div ref={sectionRef} key={curatedshow.id} className={`flex items-center gap-2 flex-col px-5 py-10 md:gap-52 justify-center flex-wrap ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'}`}>
      <div className="w-full md:w-1/3 ">
        <img src={curatedshow.img} alt="Artwork 1" className="w-full rounded-md" />
      </div>
      <div className="w-full md:w-1/3  flex flex-col gap-1 xl:gap-4">
      <h1 className="md:text-4xl sl:text-2xl text-sm font-semibold">{curatedshow.heading}</h1>
      <p className=" md:text-lg text-xs text-left">
       {curatedshow.gallery}
        </p>
        <p className=" xl:text-lg sl:text-sm text-left">
       {curatedshow.description}
        </p> 
      </div>
    </div>
        ))}
      </div>
      </div>
      </div>
  );
};

export default Aboutpage;

