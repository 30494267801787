import React from "react";
import { NavLink } from "react-router-dom";

const Artist = () => {
  return (
    <div className="flex flex-col gap-4">
        <div className="flex items-center px-5 md:px-40"><p className="md:text-4xl text-xl md:font-bold font-semibold curvy-underline">About Artist</p></div>
      <div className="md:flex items-center gap-10 justify-center animate-slideUp">
        <div className="px-5 md:w-1/3">
          <img src="work.JPG" alt="Artwork 1" className="" />
        </div>
        <div className="w-full md:w-1/2 p-5  flex flex-col gap-1 md:gap-4">
        <h1 className="md:text-4xl text-2xl font-bold">SARAN RAJ</h1>
          <p className=" md:text-lg sl:text-sm text-left">
          I come from Karadipatti, a small village in Madurai district, from the southern part of Tamil 
Nadu and hold a graduate degree in Sculpting from the Government Fine Arts College in Chennai. 
Having been based in Chennai for over 8 years, I have dedicated myself to the practice of 
creating artworks, with a specialization in sculpture. However, my artistic creations vary 
significantly depending on the context and the nature of each project, and the medium I choose 
to work with depends on the theme I aim to express. <br/>
Engaged in the study of excavations in Tamil Nadu, I find great interest in utilizing them as a 
source of inspiration for my creations. Concurrently, I am devoted to the pursuit of 
re-examining traditional art, skilfully infusing its theoretical and conceptual aspects into 
contemporary artworks. <br /> 
Through my work I intend to spark a dialogue on the existing indifferences and graded 
inequality in our society. 
          </p>
    <NavLink to='/about'><button className="relative border-2 border-pink bg-transparent w-max rounded-md p-2 font-medium text-pink transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-pink before:transition-transform before:duration-300 before:content-[''] hover:bg-pink hover:text-white before:hover:scale-x-100">Read more</button></NavLink>
        </div>
      </div>
    </div>
  );
};

export default Artist;
