import React from "react";
import Navbar from "../components/navbar";

const exhibitions = [
  {
    id: 1,
    title: "Art for Hope",
    place: '2022, Delhi',
    description: `Art For Hope” - Award From Hyundai Motor India Foundation in Delhi`,
    image: "award.png"
  },
  {
    id: 2,
    title: "Take Flight Art Grantee",
    place: '2019, Chennai',
    description: `Take Flight Art Grantee” Grand from Perch Theatre Group, Chennai`,
    image: "award.png"
  },
  {
    id: 3,
    title: "National Award For Best Art Collective",
    place: '2017, Kerala',
    description: `National Award For Best Art Collective in Students Biennale” Award From Kochi 
Muziris Biennale Foundation, Fort Kochi, Kerala.`,
    image: "award.png"
  },
  {
    id: 4,
    title: "Next Generation Artisan Award",
    place: '2015, Chennai',
    description: `Next Generation Artisan Award” From Poompuhar and Tamil Nadu Govt. Art & 
Craft Development Corporation Ltd. Chennai.`,
    image: "award.png"
  },
  {
    id: 5,
    title: "Creative Accomplishment Award”",
    place: '2014, Chennai',
    description: `Creative Accomplishment Award” For Being Creative in Academic Activities, 
Government College of Fine Arts in Chennai. `,
    image: "award.png"
  }
];

const Awards = () => {
  return (
    <div className="min-h-screen flex flex-col gap-4">
      <Navbar />
      <div className="flex items-center justify-center px-10">
        <p className="md:text-4xl text-xl font-semibold text-center curvy-underline">Awards</p>
      </div>
      {exhibitions.map((exhibition, index) => (
        <div key={exhibition.id} className={`flex items-center justify-center gap-4 flex-wrap ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'}`}>
          <div className="w-full md:w-1/4 p-10 animate-slideUp">
            <img src={exhibition.image} alt={`Artwork ${index + 1}`} className="w-full md:h-80" />
          </div>
          <div className="w-full md:w-1/4 p-10 flex flex-col gap-4 animate-slideUp">
            <div>
              <h1 className="text-2xl font-semibold">{exhibition.title}</h1>
              <p className="text-md text-pink font-semibold">{exhibition.place}</p>
            </div>
            <p className="text-sm md:text-left">
              {exhibition.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Awards;
